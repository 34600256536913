import React, { Component } from "react";
import "./Admin.scss";
// import data from "../../all__news.json";
// import { newsShedule } from "../../shared/shared";

export default class Admin extends Component {
  newsLoop = () => {
    // console.log(Object.values(data[0]).length);
    for (let i = 0; i < 4; i++) {
      // console.log(Object.values(data[0])[i]);
      return (
        <div>
          <span>{i}</span>
          {/* {Object.values(data[0])[i]} */}
        </div>
      );
    }
  };
  render() {
    console.log("Data", this.props.data);
    return (
      <div className="admin">
        {this.props.data.map((item, index) => {
          return (
            <div key={index}>
              {item.news_id} {item.title}
            </div>
          );
        })}
      </div>
    );
  }
}
