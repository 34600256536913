import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import "./NavBar.scss";
import { options } from "../../../shared/shared";

export default class NavBar extends Component {
  navbarRef = React.createRef();
  state = {
    dropDown: null,
    dropDown1: null,
    scrollLeft: 0,
  };
  handleClik = () => {
    this.setState({
      scrollLeft: 0,
    });
  };
  componentDidUpdate() {
    window.addEventListener("click", () => {
      this.navbarRef.current.scrollLeft = this.state.scrollLeft;
    });
  }
  test = (options, lastIndex) => {
    if (window.innerWidth < 768) {
      return (
        <>
          <div className="nav-link-wrapper">
            <NavLink
              to={options[5].item_list[0].route}
              exact
              className="nav-link"
            >
              {options[5].item_list[0].title}
            </NavLink>
          </div>
          <div className="nav-link-wrapper">
            <NavLink
              to={options[5].item_list[1].route}
              exact
              className="nav-link"
            >
              {options[5].item_list[1].title}
            </NavLink>
          </div>
        </>
      );
    } else {
      return (
        <div className="links last">
          <div
            className="nav-link-wrapper"
            onMouseOver={() => {
              this.setState({ dropDown: options[lastIndex].route });
            }}
            onMouseLeave={() => {
              this.setState({ dropDown: false });
            }}
            onClick={() => {
              this.setState({ dropDown: null });
            }}
          >
            <img src="/img/icons/threeDots.png" alt="" />
            <div className="botDiv">
              {this.items_list(
                options[lastIndex].route,
                options[lastIndex].item_list
              )}
            </div>
          </div>
        </div>
      );
    }
  };
  navBarOptions = () => {
    const z = () => {
      if (window.innerWidth > 768) {
        // return options.slice(0, -1);
        return options;
      } else {
        const i = [...options.slice(0, -1)];
        i.splice(2, 1);
        return i;
      }
    };
    let lastIndex = options.length - 1;
    return (
      <nav className="links" ref={this.navbarRef}>
        {z().map((option, index) => {
          return (
            <div
              className="nav-link-wrapper"
              key={index}
              onMouseOver={() => {
                this.setState({ dropDown: option.route });
              }}
              onMouseLeave={() => {
                this.setState({ dropDown: false });
              }}
              onClick={() => {
                this.handleClik();
                this.setState({ dropDown: null });
              }}
            >
              <NavLink to={option.route} exact className="nav-link">
                {option.title}
              </NavLink>
              <div className="botDiv">
                {this.items_list(option.route, option.item_list)}
              </div>
            </div>
          );
        })}
        {/* {this.test(options, lastIndex)} */}
        {/* {this.adminFunction()} */}
      </nav>
    );
  };
  // adminFunction = () => {
  //   return this.props.admin ? <NavLink to="admin">Admin</NavLink> : null;
  // };
  getDropDownMenu = (setClass, items) => {
    if (items.length > 6) {
      return this.state.dropDown === setClass
        ? "drop_down_menu"
        : "drop_down_menu close";
    } else {
      return this.state.dropDown === setClass
        ? "drop_down_menu small"
        : "drop_down_menu close";
    }
  };
  items_list = (routes, items) => {
    if (items) {
      return (
        <ul
          className={this.getDropDownMenu(routes, items)}
          onMouseOver={() => {
            this.setState({ dropDown: true });
          }}
        >
          {items.map((item, index) => {
            return (
              <NavLink key={index} to={item.route} exact>
                <img src={item.icon} alt="" />
                {item.title}
              </NavLink>
            );
          })}
        </ul>
      );
    }
  };
  render() {
    return <div className="navBar">{this.navBarOptions()}</div>;
  }
}
