import React, { Component } from "react";
import "./News2.scss";
import Backdrop from "../../components/Bars/Backdrop/Backdrop";
import AdvModule from "../../components/AdvModule/AdvModule";
import ArticalBox2 from "../../components/ArticalBox/ArticalBox";
import Pagination from "../../UI/Pagination/Pagination";
import { options } from "../../shared/shared";
import SocialLinks from "../../components/HeadHelmet/HeadHelmet";
import { withRouter } from "react-router-dom";

const removeCommentBox = () => {};

class News2 extends Component {
  state = {
    categoryNews: this.props.data,
    //state for pagination
    numberOfPages: 0,
    pageNum: 0,
    elemNum: [4, 7],
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    this.setState({
      allNews: this.props.data,
    });
    this.changeStateOnMount(this.props.data);
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.match.url !== prevProps.match.url) {
      window.scrollTo(0, 0);
      this.setState({
        elemNum: [4, 7],
        pageNum: 0,
      });
      this.changeStateOnMount(this.state.allNews);
    }
  };
  changeStateOnMount = (publishedNews) => {
    let id = this.props.match.params.id;
    let urlPart = this.props.match.path;
    const categoryNews = [];
    const actualNews1 = [];
    // console.log("Published", publishedNews, this.props.data);
    publishedNews.forEach((item) => {
      if (urlPart === "/najnovije-vesti") {
        categoryNews.push(item);
      } else if (this.urlTitle2(item.category) === id) {
        categoryNews.push(item);
      }
    });
    // console.log("Category new", categoryNews.length, this.state.pageNum);
    this.setState({
      actualNews: actualNews1,
      categoryNews: categoryNews,
    });
    this.setPageNumber(categoryNews);
  };
  urlTitle2 = (title) => {
    const cyrilic = ["č", "ć", "ž", "š", "đ", ",", ":", "-", "?", "!", "."];
    const replArray = ["c", "c", "z", "s", "dj", "", "", "", "", "", ""];
    let regex = /--/gi;
    let url_title = title
      .toLowerCase()
      .split("")
      .map((x) => {
        return cyrilic.indexOf(x) === -1 ? x : replArray[cyrilic.indexOf(x)];
      })
      .join("")
      .split(" ")
      .join("-")
      .replace(regex, "-");
    return url_title;
  };
  //func for pagination
  setPageNumber = (categoryNews) => {
    if (categoryNews) {
      let numberOfPages = Math.max(
        0,
        Math.ceil((categoryNews.length - 4) / 3 - 1)
      );
      this.setState({
        numberOfPages: numberOfPages,
      });
    }
  };
  setPaginationPage = () => {
    this.setState({
      elemNum: [4, this.state.elemNum[1] + 3],
      pageNum: this.state.pageNum + 1,
    });
  };

  setNewsBoxes = (n, newsState, classDiv, classBox) => {
    return newsState ? (
      <div className={classDiv}>
        {newsState.slice(n[0], n[1]).map((news, index) => {
          return <ArticalBox2 key={index} n={news} classes={classBox} />;
        })}
      </div>
    ) : (
      <div>Loading...</div>
    );
  };

  //change class with dif width
  setClass = () => {
    if (window.innerWidth < 500) {
      return "boxWrapper box2 news3";
    } else {
      return "boxWrapper mainBox";
    }
  };
  pageTitle = (props) => {
    let urlPath = this.props.location.pathname;
    let id = props.match.params.id;
    if (urlPath === "/najnovije-vesti") {
      return "Najnovije vesti";
    } else if (urlPath === "/vesti/subvencije") {
      return "Subvencije";
    } else {
      const x = this.props.data.find((x) => {
        return this.urlTitle2(x.category) === id;
      });
      // console.log("TEST", x);
      return x ? x.category : urlPath;
    }
  };

  render() {
    const setSocialLinks = (props) => {
      let urlPath = this.props.location.pathname;
      let id = props.match.params.id;
      // let id2 = 2;
      // if (id2 === 2) {
      //   this.props.history.push("/idemooo");
      // }
      if (urlPath === "/najnovije-vesti") {
        return (
          <SocialLinks title={"/najnovije-vesti"} post={options[2].social} />
        );
      } else if (id === "organska-proizvodnja") {
        return (
          <SocialLinks
            title={"/vesti/organska-proizvodnja"}
            post={options[3].social}
          />
        );
      } else if (id === "ostalo") {
        return <SocialLinks title={"/vesti/ostalo"} post={options[5].social} />;
      } else if (id === "subvencije") {
        return (
          <SocialLinks title={"/vesti/subvencije"} post={options[4].social} />
        );
      } else {
        const x = options[1].item_list.find((x) => {
          return this.urlTitle2(x.title) === id;
        });
        return <SocialLinks title={props.match.url} post={x.social} />;
      }
    };
    return (
      <div className="news2">
        {/* {setSocialLinks(this.props)} */}
        {/* <AdvModule classes={"adClass__XXL"} size={"xxl"} /> */}
        <section className="topNews">
          <ArticalBox2
            n={this.state.categoryNews[0]}
            classes="boxWrapper topNews"
          />
        </section>
        <section className="home__box__11">
          {this.setNewsBoxes(
            [1, 4],
            this.state.categoryNews,
            "home__rightBox",
            "boxWrapper box2"
          )}
          <div className="home__adBox">
            <AdvModule classes={"adClass xl_sticky"} size={["s", "xl"]} />
          </div>
        </section>
        <section className="home__box__2">
          {this.setNewsBoxes(
            [this.state.elemNum[0], this.state.elemNum[1]],
            this.state.categoryNews,
            "home__box__2-layout",
            this.setClass()
          )}
          <div className="no-add">
            <AdvModule classes={"adClass"} size={["s", "xl"]} />
          </div>
        </section>
        <Pagination
          numberOfPages={this.state.numberOfPages}
          pageNum={this.state.pageNum}
          clicked={this.setPaginationPage}
        />
        <div className="home__rightBox">
          <AdvModule classes={"adClass__XXL"} size={"xxl"} />
        </div>
        <Backdrop clicked={removeCommentBox()} />
      </div>
    );
  }
}
export default withRouter(News2);
