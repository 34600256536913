import React, { useEffect, useRef, useState } from "react";
import Helmet from "react-helmet";
import { options } from "../../shared/shared";

export default function HeadHelmet(props) {
  const prevPathRef = useRef(window.location.pathname);
  const loc = window.location;
  let pathPart = loc.pathname.split("/");
  let lastPathPart = pathPart[pathPart.length - 1];
  const [post, setPost] = useState(() => setArticleState());

  useEffect(() => {
    const handleLocationChange = () => {
      const newPath = window.location.pathname;
      if (newPath !== prevPathRef.current) {
        setPost(setArticleState());
        prevPathRef.current = newPath;
      }
    };

    // Dodajte event listener za promene u URL-u
    window.addEventListener("popstate", handleLocationChange);
    // Vratite cleanup funkciju za uklanjanje event listenera kada se komponenta unmountuje
    return () => {
      window.removeEventListener("popstate", handleLocationChange);
    };
  }, []);

  function setArticleState() {
    const catArr = [
      "",
      "",
      "najnovije",
      "basta",
      "cvece",
      "uredjenje-dvorista",
    ];
    if (lastPathPart === "") {
      return options[0].social;
    } else if (catArr.includes(lastPathPart)) {
      let catNews = props.allNews.filter((item) => {
        return urlTitle2(item.category) === lastPathPart && item.published;
      });
      let catHelmetText = options[catArr.indexOf(lastPathPart)].social;
      let testSocTag = {
        post: catNews[0],
        pics: catNews[0].pics,
        source: "Agroweb.rs",
        title: catHelmetText.title,
        time2: catNews[catNews.length - 1].time2,
        modified: catNews[0].time2,
        lead: catNews[0].title,
      };
      return testSocTag;
    } else if (pathPart[1] === "tag") {
      //ispraviti
      let tagNews = props.allNews.filter((item) => {
        let newTags = item.tags.map((item) => {
          return urlTitle2(item);
        });
        return newTags.includes(pathPart[2]);
      });
      let tagValue = tagNews[0].tags.filter((item) => {
        if (urlTitle2(item) === pathPart[2]) {
          return item;
        }
      });
      let socTag =
        tagValue[0].charAt(0).toUpperCase() +
        tagValue[0].slice(1) +
        " - Poljoprivredni portal Agroweb.rs";
      let testSocTag = {
        post: tagValue[0],
        pics: tagNews[0].pics,
        source: "Agroweb.rs",
        title: socTag,
        time2: tagNews[tagNews.length - 1].time2,
        modified: tagNews[0].time2,
        lead: tagNews[0].title,
      };
      return testSocTag;
    } else if (pathPart[2]) {
      let post = props.allNews.filter((post) => {
        return urlTitle2(post.title) === pathPart[2];
      });
      return post[0];
    } else {
      const x = options[1].item_list.find((item) => {
        return urlTitle2(item.title) === pathPart[1];
      });
      return options[0].social;
    }
  }
  const setSource = (item) => {
    return Array.isArray(item) ? `${item[1]}` : "agroweb.rs";
  };
  const setModifiedTime = (item) => {
    if (item) {
      return <meta property="article:modified_time" content={post.modified} />;
    }
  };
  function urlTitle2(title) {
    const cyrilic = ["č", "ć", "ž", "š", "đ", ",", ":", "-", "?", "!", "."];
    const replArray = ["c", "c", "z", "s", "dj", "", "", "", "", "", ""];
    let regex = /--/gi;
    let url_title = title
      .toLowerCase()
      .split("")
      .map((x) => {
        return cyrilic.indexOf(x) === -1 ? x : replArray[cyrilic.indexOf(x)];
      })
      .join("")
      .split(" ")
      .join("-")
      .replace(regex, "-");
    return url_title;
  }
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{post.title_2 ? post.title_2 : post.title}</title>
      <meta name="description" content={post.lead} />
      <link rel="canonical" href={"https://gardenia.rs" + loc.pathname} />
      <link rel="manifest" href="/manifest.json" />
      {/* Opengraph - Facebook */}
      <meta name="title" content={post.title_2 ? post.title_2 : post.title} />
      <meta
        property="og:title"
        content={post.title_2 ? post.title_2 : post.title}
      />
      <meta property="og:type" content="article" />
      <meta property="og:locale" content="sr-RS" />
      <meta
        property="article:publisher"
        content="https://www.facebook.com/agroweb.portal"
      />
      <meta name="author" content={setSource(post.source)} />
      <meta property="og:site_name" content="Agroweb.rs" />
      <meta property="og:image" content={post.pics[0]} />
      <meta property="og:image:secure_url" content={post.pics[0]} />
      <meta property="og:image:width" content="640" />
      <meta property="og:image:height" content="480" />
      <meta
        property="og:image:alt"
        content={post.title_2 ? post.title_2 : post.title}
      />
      <meta property="og:image:type" content="image/jpeg" />
      <meta property="og:url" content={"https://gardenia.rs" + loc.pathname} />
      <meta property="article:published_time" content={post.time2} />
      {setModifiedTime(post.modified)}
      <meta property="og:description" content={post.lead} />
      <meta name="twitter:url" content={"https://gardenia.rs" + loc.pathname} />
      <meta
        name="twitter:title"
        content={post.title_2 ? post.title_2 : post.title}
      />
      <meta name="twitter:description" content={post.lead} />
      <meta name="twitter:image" content={post.pics[0]} />
      {/* End Opengraph */}
    </Helmet>
  );
}
