import React from "react";
import "./AdvModule.scss";
import { adList2 } from "../../shared/adList.js";

const getUrl = (classes, size) => {
  //banners 300x250 and 300x600
  if (Array.isArray(size)) {
    let adListXxl = adList2.filter((x) => {
      return x.size === "s" || (x.size === "xl" && window.innerWidth > 400);
    });
    let xx = parseInt(Math.random() * (adListXxl.length - 1) + 0);
    return (
      <a
        className={classes}
        target="_blank"
        href={adListXxl[xx].route}
        rel="noopener noreferrer"
      >
        <img src={adListXxl[xx].adUrl} alt="" loading="lazy" />
      </a>
    );
  }
  //banners 970x200, 728x90
  else if (size === "xxl") {
    let adListXxl = adList2.filter((x) => {
      return x.size === "xxl";
    });
    let xx = parseInt(Math.random() * (adListXxl.length - 1) + 0);
    return (
      <a
        className={classes}
        target="_blank"
        href={adListXxl[xx].route}
        rel="noopener noreferrer"
      >
        <img src={adListXxl[xx].adUrl} alt="" loading="lazy" />
      </a>
    );
  }
  //banners 300x600
  else if (size === "xl") {
    let adListXl = adList2.filter((x) => {
      return x.size === "xl" && window.innerWidth > 400;
    });
    let xx = parseInt(Math.random() * (adListXl.length - 1) + 0);
    let ret =
      xx >= adListXl ? (
        ""
      ) : (
        <a
          className={classes}
          target="_blank"
          href={adListXl[xx].route}
          rel="noopener noreferrer"
        >
          <img src={adListXl[xx].adUrl} alt="" loading="lazy" />
        </a>
      );
    return ret;
  }
  //banners 300x250
  else if (size === "s") {
    let adListXl = adList2.filter((x) => {
      return x.size === "s";
    });
    let xx = parseInt(Math.random() * (adListXl.length - 0) + 0);
    return (
      <a
        className={classes}
        target="_blank"
        href={adListXl[xx].route}
        rel="noopener noreferrer"
      >
        <img src={adListXl[xx].adUrl} alt="" loading="lazy" />
      </a>
    );
  } else if (size === "s/f") {
    let adListXl = adList2.filter((x) => {
      return x.size === "s/f";
    });
    let a = Math.random() * (adListXl.length - 0) + 0;
    let xx =
      Math.round(a) >= adListXl.length ? (
        ""
      ) : (
        <a
          className={classes}
          target="_blank"
          href={adListXl[Math.round(a)].route}
          rel="noopener noreferrer"
        >
          <img src={adListXl[Math.round(a)].adUrl} alt="" loading="lazy" />
        </a>
      );
    return xx;
  } else {
    let adListL = adList2.filter((x) => {
      return x.size === "l";
    });
    let xx = parseInt(Math.random() * (adListL - 1) + 0);
    return (
      <a
        className={classes}
        target="_blank"
        href={adListL[xx].route}
        rel="noopener noreferrer"
      >
        <img src={adListL[xx].adUrl} alt="" loading="lazy" />
      </a>
    );
  }
};
const setAdType = (type) => {
  // console.log("AD props", type);
  if (type === "footer") {
    return " footer";
  } else {
    return "";
  }
};
const AdvModule = (props) => {
  return (
    <div className={`ad_wrapper${setAdType(props.footer)}`}>
      {/* <div className="closeButton">X</div> */}
      {/* <div className={`ad_wrapper ${props.classes}`}> */}
      {getUrl(props.classes, props.size)}
    </div>
  );
};

export default AdvModule;
