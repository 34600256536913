import React, { Component } from "react";
import "./ArticleBox.scss";
import { Link } from "react-router-dom";
import TimeFormat from "../TimeFormat/TimeFormat";
import { options } from "../../shared/shared";
// import Buttons from "../../UI/Button/Buttons";
import { withRouter } from "react-router-dom";

class ArticalBox extends Component {
  state = {
    loaded: false,
    error: false,
    fallBackSrc: "/img/fallBack.jpg",
  };
  urlTitle = (a) => {
    const cyrilic = ["č", "ć", "ž", "š", "đ", ",", ":", "-", "?", "!", "."];
    const replArray = ["c", "c", "z", "s", "dj", "", "", "", "", "", ""];
    let regex = /--/gi;
    let d = a
      .toLowerCase()
      .split("")
      .map((x) => {
        return cyrilic.indexOf(x) === -1 ? x : replArray[cyrilic.indexOf(x)];
      });
    return d.join("").split(" ").join("-").replace(regex, "-");
  };
  setPreUrlTitle = (item, allC, cat) => {
    const test4 = allC.filter((e) => {
      return this.urlTitle(e.title) === this.urlTitle(item.category);
    })[0];
    if (cat) {
      return test4.route;
    } else {
      return test4.route + "/" + this.urlTitle(item.title);
    }
  };
  //image fallBack
  onImageError = () => {
    console.log("Error");
    this.setState({ error: true });
  };
  render() {
    const articleData = this.props.n;
    const classes = this.props.classes;
    const allCategories = [];
    const test2 = options.map((item) => {
      if (item.item_list) {
        item.item_list.map((e) => {
          allCategories.push(e);
        });
      } else {
        allCategories.push(item);
      }
    });
    let imgSrc = !this.state.error
      ? articleData.pics[0]
      : this.state.fallBackSrc;

    let setTitle = articleData.title_2
      ? articleData.title_2
      : articleData.title;
    return (
      <div className={classes}>
        <div className="artBox-wrapper">
          <Link
            to={this.setPreUrlTitle(articleData, allCategories)}
            className="artBox-img"
          >
            <img
              src={imgSrc}
              alt={articleData.tags[0]}
              onError={this.onImageError}
              loading="lazy"
            />
          </Link>
          <div className="artBox-desc">
            <Link to={this.setPreUrlTitle(articleData, allCategories)}>
              <h3>{setTitle}</h3>
            </Link>
            <div className="artBox-desc-other">
              <Link to={this.setPreUrlTitle(articleData, allCategories, true)}>
                {articleData.category}
              </Link>
              <span>
                |<TimeFormat timePost={articleData.time2} classes={"noDay"} />
              </span>
            </div>
            <span className="artBox-lead">{articleData.lead}..</span>
            <button
              classes="test1111"
              onClick={() => {
                // console.log("Idemoooo");
                this.props.history.push(
                  this.setPreUrlTitle(articleData, allCategories)
                );
              }}
            >
              Nastavi čitanje
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ArticalBox);
