export const adList2 = [
  {
    adUrl: "/img/ads_img/galenika2_300x600.jpg",
    alt: "Tekton",
    size: "xl",
    route: "https://www.fitofarmacija.rs/proizvod/tekton",
  },
  {
    adUrl: "/img/ads_img/spargle_300x600.jpg",
    alt: "Beobasta",
    size: "xl",
    route: "https://shop.beobasta.rs/spargle/",
  },
  // {
  //   adUrl: "/img/ads_img/tau300x600.png",
  //   alt: "Beobasta",
  //   size: "xl",
  //   route: "https://tausolutions.rs/novosti.html",
  // },
  {
    adUrl: "/img/ads_img/syngenta_728x90.png",
    alt: "",
    size: "xxl",
    route: "https://www.syngenta.rs/",
  },
  // {
  //   adUrl: "/img/ads_img/galerija.png",
  //   alt: "",
  //   size: "xxl",
  //   route: "https://www.bulevarumetnosti.rs/",
  // },
  // {
  //   adUrl: "/img/ads_img/ikone970.png",
  //   alt: "",
  //   size: "xxl",
  //   route: "https://ikone.rs/crkveni-kalendar",
  // },
  // {
  //   adUrl: "/img/ads_img/tau300x250.png",
  //   alt: "Ikone.rs",
  //   size: "s",
  //   route: "https://ikone.rs/crkveni-kalendar",
  // },
  // {
  //   adUrl: "/img/ads_img/tau300x250_2.png",
  //   alt: "Tausolutions",
  //   size: "s",
  //   route: "https://tausolutions.rs/novosti.html",
  // },
  {
    adUrl: "/img/ads_img/idemo2.jpg",
    alt: "",
    size: "s",
    route: "https://www.dunav.com/osiguranje/poljoprivreda/",
  },
  // {
  //   adUrl: "/img/ads_img/11111.jpg",
  //   alt: "Sajam",
  //   size: "s",
  //   route:
  //     "https://sajam.net/sr/kalendar-2022/89-medjunarodni-poljoprivredni-sajam",
  // },

  {
    adUrl: "/img/ads_img/dijamant.jpg",
    alt: "",
    size: "xxl",
    route: "https://www.dijamant.rs/",
  },
  {
    adUrl: "/img/ads_img/kazani_728x90.jpg",
    alt: "",
    size: "xxl",
    route: "https://www.destilatori-kazani.com/",
  },
  {
    adUrl: "/img/ads_img/kazani_728x90.jpg",
    alt: "",
    size: "e",
    route: "https://www.destilatori-kazani.com/",
  },
  // {
  //   adUrl: "/img/ads_img/sajam.jpg",
  //   alt: "",
  //   size: "xxl",
  //   route:
  //     "https://sajam.net/sr/kalendar-2022/89-medjunarodni-poljoprivredni-sajam",
  // },
  {
    adUrl: "/img/ads_img/baner.jpg",
    alt: "",
    size: "l",
    route: "https://www.creditagricole.rs/Pocetna",
  },
  {
    adUrl: "/img/ads_img/delta.jpg",
    alt: "",
    size: "xl",
    route:
      "https://www.deltaagrar.rs/trgovina_i_distribucija/seme/seme_psenice_.368.html",
  },
  // {
  //   adUrl: "/img/ads_img/organic.png",
  //   alt: "",
  //   size: "xl",
  //   route: "http://capitalgreen.rs/",
  // },
  {
    adUrl: "/img/ads_img/basf.jpg",
    alt: "",
    size: "xl",
    route:
      "https://www.agro.basf.rs/sr/Proizvodi/Inovacije-u-poljoprivredi/Sercadis/",
  },
  {
    adUrl: "/img/ads_img/stihl_728x90.png",
    alt: "",
    size: "e",
    route: "https://www.stihl.rs/specijalna_ponuda.aspx",
  },
];
