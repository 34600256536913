export const newsBoxes = [
  {
    box1: [3, `this.state.filtered1`, "Najnovije vesti", "boxWrapper sideBox"],
  },
];

export const newsShedule = [
  { home: [49, 47, 30] }, //actual news ids
  { home_topNews_1: [1, 2] },
];
export const newsShedule2 = [
  [[1, 4], "home__rightBox", "boxWrapper box1"],
  [[4, 7], "home__leftBox", "boxWrapper box1"],
  // [[0, 3], "box3__left", "boxWrapper box3"],
  [[8, 10], "home__box__4-layout", "boxWrapper mainBox bigBox"],
  [[10, 14], "home__box__4-layout", "boxWrapper box4"],
];
export const options = [
  //0
  {
    title: "Početna",
    route: "/",
    icon: "/img/icons/home.png",
    social: {
      title: "Gardenia.rs - vaš vodič za savršenu baštu i dvorište",
      lead: "Uživajte u svojoj bašti i dvorištu uz Gardenia.rs! Pronađite savete za uzgajanje biljaka i cveća, održavanje dvorišta i izbor pravih alata za vaše potrebe. Saznajte kako da pretvorite vaše dvorište u rajski kutak uz naše korisne članke i savete.",
      pics: "/img/test3.jpg",
    },
  },
  //1
  {
    title: "Novosti",
    route: "/novosti",
    icon: "/img/icons/other.png",
    item_list: [
      {
        title: "Voćarstvo",
        route: "/vesti/vocarstvo",
        icon: "/img/icons/vocke.png",
        social: {
          title: "Voćarstvo",
          lead: "Najnovije vesti - Voćarstvo",
          pics: "/img/test3.jpg",
        },
      },
      {
        title: "Vinogradarstvo",
        route: "/vesti/vinogradarstvo",
        icon: "/img/icons/vinogradarstvo.png",
        social: {
          title: "Vinogradarstvo",
          lead: "Najnovije vesti - Vinogradarstvo",
          pics: "/img/test3.jpg",
        },
      },
      {
        title: "Stočarstvo",
        route: "/vesti/stocarstvo",
        icon: "/img/icons/stocarstvo.png",
        social: {
          title: "Stočarstvo",
          lead: "Najnovije vesti - Stočarstvo",
          pics: "/img/test3.jpg",
        },
      },
      {
        title: "Pčelarstvo",
        route: "/vesti/pcelarstvo",
        icon: "/img/icons/honey.png",
        social: {
          title: "Pčelarstvo",
          lead: "Najnovije vesti - Pčelarstvo",
          pics: "/img/test3.jpg",
        },
      },
      {
        title: "Ratarstvo",
        route: "/vesti/ratarstvo",
        icon: "/img/icons/ratarstvo.png",
        social: {
          title: "Ratarstvo",
          lead: "Najnovije vesti - Ratarstvo",
          pics: "/img/test3.jpg",
        },
      },
      {
        title: "Povrtarstvo",
        route: "/vesti/povrtarstvo",
        icon: "/img/icons/povrtarstvo.png",
        social: {
          title: "Povrtarstvo",
          lead: "Najnovije vesti - Povrtarstvo",
          pics: "/img/test3.jpg",
        },
      },
      {
        title: "Mehanizacija",
        route: "/vesti/mehanizacija",
        icon: "/img/icons/mehanizacija.png",
        social: {
          title: "Mehanizacija",
          lead: "Najnovije vesti - Mehanizacija",
          pics: "/img/test3.jpg",
        },
      },
      // {
      //   title: "Prehranbena industrija",
      //   route: "/vesti/prehranbena-industrija",
      //   icon: "/img/icons/industrija.png",
      //   social: {
      //     title: "Prehranbena industrija",
      //     lead: "Najnovije vesti - Prehranbena industrija",
      //     pics: "/img/test3.jpg",
      //   },
      // },
      // {
      //   title: "Zaštita bilja",
      //   route: "/vesti/zastita-bilja",
      //   icon: "/img/icons/zastita.png",
      //   social: {
      //     title: "Zaštita bilja",
      //     lead: "Najnovije vesti - Zaštita bilja",
      //     pics: "/img/test3.jpg",
      //   },
      // },
      // {
      //   title: "Ukrasno bilje",
      //   route: "/vesti/ukrasno-bilje",
      //   icon: "/img/icons/ukrasno.png",
      //   social: {
      //     title: "Ukrasno bilje",
      //     lead: "Najnovije vesti - Ukrasno bilje",
      //     pics: "/img/test3.jpg",
      //   },
      // },
    ],
    social: {
      title: "Novosti",
      lead: "Gardenia magazine",
      pics: "/img/test3.jpg",
    },
  },
  //2
  {
    title: "Bašta",
    route: "/basta",
    icon: "/img/icons/manifestacije.png",
    social: {
      title: "Bašta",
      lead: "Bašta",
      pics: "/img/test3.jpg",
    },
  },
  //3
  {
    title: "Cveće",
    route: "/vesti/cvece",
    icon: "/img/icons/other.png",
    social: {
      title: "Cveće",
      lead: "Cveće",
      pics: "/img/test3.jpg",
    },
  },
  // {
  //   title: "Organska proizvodnja",
  //   route: "/vesti/organska-proizvodnja",
  //   icon: "/img/icons/organic.png",
  //   social: {
  //     title: "Organska proizvodnja",
  //     lead: "Najnovije vesti - Organska proizvodnja",
  //     pics: "/img/test3.jpg",
  //   },
  // },
  // {
  //   title: "Život na selu",
  //   route: "/zivot-na-selu",
  //   icon: "/img/icons/other.png",
  //   item_list: [
  //     {
  //       title: "Tradicionalni recepti",
  //       route: "/zivot-na-selu/tradicionalni-recepti",
  //       icon: "/img/icons/other.png",
  //     },
  //     {
  //       title: "Seoske priče",
  //       route: "/vesti/seoske-price",
  //       icon: "/img/icons/other.png",
  //     },
  //     {
  //       title: "Zdrav život",
  //       route: "/zivot-na-selu/zdrav-zivot",
  //       icon: "/img/icons/other.png",
  //     },
  //     {
  //       title: "Običaji",
  //       route: "/zivot-na-selu/obicaji",
  //       icon: "/img/icons/other.png",
  //     },
  //   ],
  // },
  //4
  {
    title: "Uređenje dvorišta",
    route: "/uredjenje-dvorista",
    icon: "/img/icons/other.png",
    social: {
      title: "Uređenje dvorišta",
      lead: "Uređenje dvorišta",
      pics: "/img/test3.jpg",
    },
  },
  //5
  {
    title: "Ostalo",
    route: "/vesti/ostalo",
    icon: "/img/icons/other.png",
    item_list: [
      {
        title: "Seoski turizam",
        route: "/seoski-turizam",
        icon: "/img/icons/other.png",
      },
      {
        title: "Kalkulatori",
        route: "/kalkulatori",
        icon: "/img/icons/other.png",
      },
      {
        title: "Organska proizvodnja",
        route: "/vesti/organska-proizvodnja",
        icon: "/img/icons/organic.png",
        social: {
          title: "Organska proizvodnja",
          lead: "Najnovije vesti - Organska proizvodnja",
          pics: "/img/test3.jpg",
        },
      },
      {
        title: "Manifestacije",
        route: "/vesti/manifestacije",
        icon: "/img/icons/manifestacije.png",
        social: {
          title: "Manifestacije",
          lead: "Manifestacije",
          pics: "/img/test3.jpg",
        },
      },
      {
        title: "Ostalo",
        route: "/vesti/ostalo",
        icon: "/img/icons/other.png",
      },
    ],
    social: {
      title: "Ostalo",
      lead: "Poljoprivreda - Ostale vesti",
      pics: "/img/test3.jpg",
    },
  },
];

export const footer = [
  ["O nama", "/o-nama"],
  ["Marketing", "/marketing"],
  ["Pravila korišćenja", "/pravila-koriscenja"],
  ["Politika privatnosti", "/politika-privatnosti"],
];

export const infoText = [
  [
    "O nama",
    "Agrweb.rs je poljoprivredni portal koji pruža sveobuhvatne informacije o poljoprivrednoj proizvodnji u Srbiji. Na našem portalu možete pronaći najnovije vesti, savete i tehnologije iz oblasti poljoprivrede, kao i informacije o proizvodima i uslugama koje nude domaći poljoprivredni proizvođači.<br /> Cilj nam je da pomognemo poljoprivrednicima da unaprede svoju proizvodnju i da budu uspešni u svojim poslovima. Pratite nas i informišite se o tome kako da unapredite svoju poljoprivrednu proizvodnju. <br /> Pored toga, na portalu nudimo i mogućnost oglašavanja za sve one koji žele da promovišu svoje proizvode i usluge.",
  ],
  ["Marketing", ""],
  ["Pravila korišćenja", ""],
  ["Politika privatnosti", ""],
];

export const asideBoxes = [
  [0, 3, "Najnovije vesti", "boxWrapper sideBox"],
  [3, 3, "Najčitanije vesti", "boxWrapper sideBox"],
  [
    1,
    2,
    "Najnovije vesti - ",
    "boxWrapper mainBox new",
    "home__rightBox",
    true,
    "home__box__13",
  ],
  [
    2,
    3,
    "Preporučujemo",
    "boxWrapper box2",
    "home__rightBox",
    false,
    "home__box__11",
  ],
];
