import React from "react";

const setTime = (postTime, classes) => {
  var months = [
    "Januar",
    "Februar",
    "Mart",
    "April",
    "maj",
    "Jun",
    "Jul",
    "Avgust",
    "Septembar",
    "Oktobar",
    "Novembar",
    "Decembar",
  ];
  var days = [
    "Nedelja",
    "Ponedeljak",
    "Utorak",
    "Sreda",
    "Četvrtak",
    "Petak",
    "Subota",
  ];
  let e = new Date(postTime);
  let day = days[e.getDay()];
  let month = months[e.getMonth()];
  let date = e.getDate();
  if (classes === "noDay") {
    return <>{" " + date + ". " + month}</>;
  } else {
    return <>{day + ", " + date + ". " + month}</>;
  }
};

export default function TimeFormat(props) {
  return <>{setTime(props.timePost, props.classes)}</>;
}
