import React from "react";
import "./TopBar.scss";
// import { NavLink } from "react-router-dom";
export default class TopBar extends React.Component {
  state = {
    showBar: "topBar",
  };
  componentDidMount = () => {
    window.addEventListener("scroll", this.handleScroll);
  };

  handleScroll = () => {
    if (window.pageYOffset < 150) {
      this.setState({
        showBar: "topBar",
      });
    } else {
      this.setState({
        showBar: "topBar close",
      });
    }
  };
  render() {
    return (
      <div className={this.state.showBar}>
        <img src="../../../img/logo3.png" alt="GARDENIA MAGAZINE" />
        {/* <a
          href="mailto:office@gardenia.rs"
          className="topBar__link"
          rel="noopener noreferrer"
        >
          redakcija@gardenia.rs
        </a>
        <div className="topBar__link">
          <a
            href="https://www.instagram.com/agroweb.portal/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-instagram"></i>
          </a>
          <a
            href="https://www.facebook.com/agroweb.portal"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-facebook"></i>
          </a>
        </div> */}
      </div>
    );
  }
}
