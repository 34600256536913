import React, { Component } from "react";
import "./News.scss";
import news from "../../all__news";
import { options } from "../../shared/shared";
import Backdrop from "../../components/Bars/Backdrop/Backdrop";
import AdvModule from "../../components/AdvModule/AdvModule";
import ArticalBox from "../../components/ArticalBox/ArticalBox";
import SocialLinks from "../../components/HeadHelmet/HeadHelmet";
import { withRouter } from "react-router-dom";

// const removeCommentBox = () => {};
class News extends Component {
  state = {
    filtered: this.props.data,
    allNews: this.props.data,
    allNewsCat1: this.props.data,
    allNewsCat2: this.props.data,
    allNewsCat3: this.props.data,
  };
  componentDidMount = () => {
    const publishedNews = [];
    this.props.data.map((x) => {
      if (x.published) {
        publishedNews.push(x);
      }
    });
    this.setState({
      filtered: publishedNews,
    });
    window.scrollTo(0, 0);
    this.changeStateOnMount(
      "Voćarstvo",
      "Organska proizvodnja",
      "Ostalo",
      publishedNews
    );
  };
  changeStateOnMount = (newsCat1, newsCat2, newsCat3, publishedNews) => {
    const allNews1 = [];
    const allNewsCat1 = [];
    const allNewsCat2 = [];
    const allNewsCat3 = [];
    publishedNews.map((x) => {
      if (newsCat1.includes(x.category)) {
        allNewsCat1.push(x);
      } else if (newsCat2.includes(x.category)) {
        allNewsCat2.push(x);
      } else if (newsCat3.includes(x.category)) {
        allNewsCat3.push(x);
      } else {
        allNews1.push(x);
      }
    });
    this.setState({
      allNews: allNews1,
      allNewsCat1: allNewsCat1,
      allNewsCat2: allNewsCat2,
      allNewsCat3: allNewsCat3,
    });
  };
  setNewsBoxes = (n, newsState, classDiv, classBox) => {
    return newsState ? (
      <div className={classDiv}>
        {newsState.slice(n[0], n[1]).map((news, index) => {
          return (
            <ArticalBox
              key={index}
              n={news}
              classes={this.displayClassName(classBox, n[0], index)}
            />
          );
        })}
      </div>
    ) : (
      <div>Loading...</div>
    );
  };
  urlTitle2 = (title) => {
    const cyrilic = ["č", "ć", "ž", "š", "đ", ",", ":", "-", "?", "!", "."];
    const replArray = ["c", "c", "z", "s", "dj", "", "", "", "", "", ""];
    let regex = /--/gi;
    let url_title = title
      .toLowerCase()
      .split("")
      .map((x) => {
        return cyrilic.indexOf(x) === -1 ? x : replArray[cyrilic.indexOf(x)];
      })
      .join("")
      .split(" ")
      .join("-")
      .replace(regex, "-");
    return url_title;
  };
  displayClassName = (classes, n, index) => {
    if (window.innerWidth < 768 && n === 4 && index === 0) {
      return "boxWrapper mainBox box3 dark";
    } else {
      return classes;
    }
  };
  render() {
    window.scrollTo(0, 0);
    const setSocialLinks = this.state.allNews ? (
      <SocialLinks
        title={this.urlTitle2(this.props.match.url)}
        post={options[1].social}
      />
    ) : (
      <div>Loading...</div>
    );
    return (
      <div className="news">
        {setSocialLinks}
        <AdvModule classes={"adClass__XXL"} size={"xxl"} />
        <h1>Vesti</h1>
        <section className="home__box__1">
          <div className="home__box">
            <ArticalBox n={this.state.filtered[0]} classes="mainNews" />
          </div>
          {this.setNewsBoxes(
            [1, 4],
            this.state.allNews,
            "home__rightBox",
            "boxWrapper box1"
          )}
        </section>
        <section className="home__box__11">
          {this.setNewsBoxes(
            [4, 7],
            this.state.allNews,
            "home__rightBox",
            "boxWrapper box2"
          )}
          <div className="home__adBox">
            <AdvModule classes={"adClass"} size={["s", "xl"]} />
          </div>
        </section>
        <section className="home__box__3">
          <h2>Voćarstvo</h2>
          {this.setNewsBoxes(
            [0, 3],
            this.state.allNewsCat1,
            "home__box__3-wrapper",
            "boxWrapper mainBox box3 dark"
          )}
        </section>
        <AdvModule classes={"adClass__XXL"} size={"xxl"} />
        <section className="home__box__2">
          <h2>Organska proizvodnja</h2>
          {this.setNewsBoxes(
            [0, 3],
            this.state.allNewsCat2,
            "home__box__2-layout",
            "boxWrapper mainBox box3 white"
          )}
        </section>
        <section className="home__box__71">
          <h2>Subvencije</h2>
          <div className="home__box__71-wrapper">
            {this.setNewsBoxes(
              [0, 3],
              this.state.allNewsCat2,
              "home__rightBox",
              "boxWrapper box2"
            )}
            <div className="home__adBox">
              <AdvModule classes={"adClass"} size={"xl"} />
            </div>
          </div>
        </section>
        <section className="home__box__3">
          <div className="home_3_title">
            <h2>Život na selu</h2>
          </div>
          {this.setNewsBoxes(
            [0, 3],
            this.state.allNewsCat1,
            "home__box__3-wrapper",
            "boxWrapper mainBox box3 dark"
          )}
        </section>
        <section className="home__box__71">
          <h2>Ostalo</h2>
          <div className="home__box__71-wrapper">
            {this.setNewsBoxes(
              [0, 3],
              this.state.allNewsCat3,
              "home__rightBox",
              "boxWrapper box2"
            )}
            <div className="home__adBox">
              <AdvModule classes={"adClass"} size={"xl"} />
            </div>
          </div>
        </section>
        <AdvModule classes={"adClass__XXL"} size={"xxl"} />
        <Backdrop />
      </div>
    );
  }
}
export default withRouter(News);
