import React from "react";
import { Component } from "react";
import "./SimplePage.scss";
import data from "../../all__news.json";
import { withRouter } from "react-router-dom";
import ArticalBox2 from "../../components/ArticalBox/ArticalBox";
import { infoText } from "../../shared/shared";

class SimplePage extends Component {
  state = {
    categoryNews: data,
  };
  componentDidMount = () => {
    const publishedNews = [];
    data.forEach((x) => {
      if (x.published) {
        publishedNews.push(x);
      }
    });
    this.setState({
      allNews: publishedNews,
    });
    this.changeStateOnMount(publishedNews);
  };
  changeStateOnMount = (publishedNews) => {
    window.scrollTo(0, 0);
  };
  setNewsBoxes = (n, newsState, classDiv, classBox) => {
    return newsState ? (
      <div className={classDiv}>
        {newsState.slice(n[0], n[1]).map((news, index) => {
          return <ArticalBox2 key={index} n={news} classes={classBox} />;
        })}
      </div>
    ) : (
      <div>Loading...</div>
    );
  };
  setClass = () => {
    if (window.innerWidth < 500) {
      return "boxWrapper box2 news3";
    } else {
      return "boxWrapper mainBox";
    }
  };
  setPositionInArray = (arr, loc) => {
    let z = arr.indexOf(loc);
    return z;
  };
  render() {
    let loc = this.props.location.pathname;
    let arr = [
      "/o-nama",
      "/marketing",
      "/pravila-koriscenja",
      "/politika-privatnosti",
    ];
    return (
      <div className="simplePage">
        <h1>{infoText[this.setPositionInArray(arr, loc)][0]}</h1>
        <p
          dangerouslySetInnerHTML={{
            __html: infoText[this.setPositionInArray(arr, loc)][1],
          }}
        ></p>
        <h1>Najnovije vesti</h1>
        <section className="home__box__11">
          {this.setNewsBoxes(
            [1, 7],
            this.state.allNews,
            "home__box__2-layout",
            this.setClass()
          )}
        </section>
      </div>
    );
  }
}
export default withRouter(SimplePage);
