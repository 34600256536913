import React from "react";
import "./SideDrawer.scss";
import { NavLink } from "react-router-dom";
import { options } from "../../../shared/shared";

export default function SideDrawer(props) {
  let drawerClasses = "side-drawer";
  if (props.show) {
    drawerClasses = "side-drawer open";
  }

  return (
    <div onClick={props.clicked}>
      <nav className={drawerClasses}>
        <ul>
          {options.map((option, index) => {
            return (
              <div key={index}>
                <li>
                  <NavLink to={option.route} exact className="top-link2">
                    <img src={option.icon} loading="lazy" alt={option.title} />{" "}
                    {option.title}
                    {/* {items_list(option.item_list)} */}
                  </NavLink>
                </li>
                <li className="subNav">
                  {items_list(option.route, option.item_list)}
                </li>
              </div>
            );
          })}
        </ul>
      </nav>
    </div>
  );
}
const items_list = (r, items) => {
  if (items) {
    return (
      <ul className="subUl">
        {items.map((item, index) => {
          return (
            <NavLink key={index} to={item.route}>
              <img src={item.icon} loading="lazy" alt={item.title} />
              {item.title}
            </NavLink>
          );
        })}
      </ul>
    );
  }
};
