import React, { Suspense, lazy, useEffect, useState, useRef } from "react";
import { useLocation, useParams, Link } from "react-router-dom";
import "./SinglePost.scss";
import TimeFormat from "../../components/TimeFormat/TimeFormat";
import { asideBoxes } from "../../shared/shared";
// import { afterContent, bilbord } from "../../shared/AdSenseParams";

const BodyText = lazy(() => import("../../components/BodyText/BodyText"));
const SocialButtons = lazy(() =>
  import("../../UI/SocialButtons/SocialButtons")
);
const AsideBox = lazy(() => import("../../components/AsideBox/AsideBox"));
// const AdExModule = lazy(() => import("../../components/AdvModule/AdExModule"));

export default function SinglePost(props) {
  const { category, id } = useParams();
  const [post, setPost] = useState(() => setArticleState());
  const screenWidth = window.innerWidth;
  const location = useLocation();
  const previouslocation = useRef("");

  useEffect(() => {
    window.scrollTo(0, 0);
    if (
      previouslocation.current &&
      previouslocation.current !== location.pathname
    ) {
      setPost(setArticleState());
      // if (previouslocation.current === location.pathname) {
      //   window.scrollTo(0, 0);
      // }
    }
    previouslocation.current = location.pathname;
  }, [location.pathname]);

  function setArticleState() {
    return props.data.find((post) => {
      return urlTitle2(post.title) === id;
    });
  }

  function urlTitle2(title) {
    const cyrilic = ["č", "ć", "ž", "š", "đ", ",", ":", "-", "?", "!", "."];
    const replArray = ["c", "c", "z", "s", "dj", "", "", "", "", "", ""];
    let regex = /--/gi;
    let url_title = title
      .toLowerCase()
      .split("")
      .map((x) => {
        return cyrilic.indexOf(x) === -1 ? x : replArray[cyrilic.indexOf(x)];
      })
      .join("")
      .split(" ")
      .join("-")
      .replace(regex, "-");
    return url_title;
  }
  const setSource = (item) => {
    if (Array.isArray(item)) {
      return `Autor: ${item[1]}`;
    } else {
      return `Izvor: ${item}`;
    }
  };
  const id2 = () => {
    if (category === "subvencije") {
      return "Spec";
    }
  };
  // const adLoading = (ind, adPos) => {
  //   if (ind === "pos-1" && screenWidth > 768) {
  //     return (
  //       <div className="wrapper-90">
  //         <Suspense fallback={<div></div>}>
  //           <AdExModule key={location.pathname} data={adPos} />
  //         </Suspense>
  //       </div>
  //     );
  //   } else if (ind === "pos-2" && screenWidth > 1024) {
  //     return (
  //       <div className="wrapper-after">
  //         <Suspense fallback={<div></div>}>
  //           <AdExModule key={location.pathname} data={adPos} />
  //         </Suspense>
  //       </div>
  //     );
  //   }
  // };

  return (
    <div className={`singlePost ${id2()}`}>
      {/* {adLoading("pos-1", bilbord)} */}
      <div className="content">
        <main className="mainContent">
          <article className="mainContent-wrapper">
            {/* <span className="mainContent-cat">{post.category}</span> */}
            <h1 className="mainContent-title">
              {post.title_2 ? post.title_2 : post.title}
            </h1>
            <p className="mainContent-lead">
              <strong>{post.lead}</strong>
            </p>
            <div className="time">
              {setSource(post.source)}, Datum:{" "}
              <TimeFormat
                timePost={post.modified ? post.modified : post.time2}
              />
            </div>
            <figure className="mainContent-img">
              <img src={`${post.pics[0]}`} alt={post.tags[0]} />
              <figcaption className="mainContent-img_source">
                <span>Foto: </span>
                <span dangerouslySetInnerHTML={{ __html: post.pics[1] }}></span>
              </figcaption>
            </figure>
            <div className="mainContent-social">
              <Suspense fallback={<div></div>}>
                <SocialButtons
                  testImg={post.pics[0]}
                  location={location.pathname}
                  postTitle={post.title_2 ? post.title_2 : post.title}
                />
              </Suspense>
            </div>
            <div className="mainContent-bodyText">
              <Suspense fallback={<div></div>}>
                <BodyText bodyText={post.body} />
              </Suspense>
            </div>
            <div className="tags">
              <span className="tags-title">Tagovi</span>
              <div className="tags__arr">
                {post.tags.map((item, index) => {
                  return (
                    <Link key={index} to={`/tag/${urlTitle2(item)}`}>
                      {item}
                    </Link>
                  );
                })}
              </div>
            </div>
          </article>
          {/* End */}
          {/* <Suspense fallback={<div>Loading...</div>}>
            <AsideBox
              post={post}
              data={props.data}
              items={[[...asideBoxes[2]], [...asideBoxes[3]]]}
              classes="newsAside foot"
              dispAds={false}
            />
          </Suspense> */}
        </main>
        {/* <Suspense fallback={<div>Loading...</div>}>
          <AsideBox
            data={props.data}
            post={post}
            items={[[...asideBoxes[0]], [...asideBoxes[1]]]}
            classes="newsAside"
            dispAds={true}
          />
        </Suspense> */}
      </div>
      {/* {adLoading("pos-2", afterContent)} */}
    </div>
  );
}
